
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface Research {
  icon: {
    file: string;
    color: string;
  };
  id: string;
  title: string;
  date: string;
  advisor: string;
  submission?: {
    createdAt: string;
    files: string[];
    answer: string;
  };
  spec?: boolean;
}

export default defineComponent({
  name: "teacherResearches",
  components: {},
  setup() {
    const { t } = useI18n();

    const researches: Research[] = [];
    const filteredResearches = ref<Research[]>([]);

    const search = ref<string>("");

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("course.researches"), []);
    });

    ApiService.get(`/teachers/research`)
      .then(({ data }) => {
        data.forEach((research) => {
          researches.push(research);
        });
        filteredResearches.value = researches;
        loading.value = false;
      })
      .catch((e) => console.log(e));

    const filterResearches = () => {
      currentPage.value = 1;
      filteredResearches.value = researches.filter((research) =>
        research.title.toLowerCase().includes(search.value.toLowerCase())
      );
    };

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredResearches.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredResearches.value.slice(startIndex - 1, endIndex);
    });

    return {
      researches,
      search,
      t,
      moment,
      filterResearches,
      filteredResearches,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      loading,
    };
  },
});
